import './App.css';

function App() {
  return (

<div>
  <nav class="navbar navbar-expand-sm bg-light navbar-light">
    <div class="container-fluid">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link active" href="/">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/programming">Programming</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/battletech">Battletech</a>
        </li>
      </ul>
    </div>
  </nav>
  <h1>Page coming soon.</h1>
</div>

  );
}

export default App;
